<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="800px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          {{ title }}
          <span
            v-if="eventId"
            class="body-2"
          >
            <i>#{{ event.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="name"
              >
                <v-text-field
                  v-model="event.name"
                  hide-details="auto"
                  label="Nome"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="date"
                  >
                    <v-text-field
                      v-model="dateFormated"
                      v-mask="'##/##/####'"
                      placeholder="01/01/2022"
                      label="Data"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="event.date = dateFormatIso(dateFormated)"
                      @click:append="dateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="event.date"
                  no-title
                  class="ma-0"
                  @input="dateMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col md="2">
              <validation-provider
                v-slot="{ errors }"
                vid="hour"
              >
                <v-text-field
                  v-model="event.hour"
                  hide-details="auto"
                  label="Hora"
                  v-mask="'##:##'"
                  placeholder="12:00"
                  append-icon="mdi-clock"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>


            <v-col md="4">
              <v-menu
                v-model="dateEndMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="dateEnd"
                  >
                    <v-text-field
                      v-model="dateEndFormated"
                      v-mask="'##/##/####'"
                      placeholder="01/01/2022"
                      label="Data Fim"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="event.dateEnd = dateFormatIso(dateEndFormated)"
                      @click:append="dateEndMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="event.dateEnd"
                  no-title
                  class="ma-0"
                  @input="dateEndMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col md="2">
              <validation-provider
                v-slot="{ errors }"
                vid="hourEnd"
              >
                <v-text-field
                  v-model="event.hourEnd"
                  hide-details="auto"
                  label="Hora Fim"
                  v-mask="'##:##'"
                  placeholder="13:00"
                  append-icon="mdi-clock"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="8">
              <validation-provider
                v-slot="{ errors }"
                vid="placeCity"
              >
                <v-text-field
                  v-model="event.placeCity"
                  hide-details="auto"
                  label="Cidade"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col md="4">
              <validation-provider
                v-slot="{ errors }"
                vid="nextEnrolledSequence"
              >
                <v-text-field
                  v-model="event.nextEnrolledSequence"
                  hide-details="auto"
                  :label="!!event.id ? 'Próximo Número Certificado' : 'Número Inicial Certificado'"
                  :error-messages="errors"
                  :disabled="!!event.id"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="certificateText"
              >
                <v-textarea
                  v-model="event.certificateText"
                  hide-details="auto"
                  label="Texto do Certificado"
                  rows="3"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="details"
              >
                <v-textarea
                  v-model="event.details"
                  hide-details="auto"
                  label="Detalhes"
                  rows="3"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="4">
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    vid="banner"
                  >
                    <v-file-input
                      v-model="files.banner"
                      prepend-icon=""
                      accept="image/*"
                      hide-details="auto"
                      label="Banner"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="hasFile('banner')">
                <v-col md="6">
                  <v-img
                    :src="`data:image/jpeg;base64,${event.files.banner}`"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4">
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    vid="badgeBackground"
                  >
                    <v-file-input
                      v-model="files.badgeBackground"
                      prepend-icon=""
                      accept="image/*"
                      hide-details="auto"
                      label="Fundo Crachá"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="hasFile('badgeBackground')">
                <v-col md="6">
                  <v-img
                    :src="`data:image/jpeg;base64,${event.files.badgeBackground}`"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col md="4">
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    vid="certificateBackground"
                  >
                    <v-file-input
                      v-model="files.certificateBackground"
                      prepend-icon=""
                      accept="image/*"
                      hide-details="auto"
                      label="Fundo Certificado"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="hasFile('certificateBackground')">
                <v-col md="6">
                  <v-img
                    :src="`data:image/jpeg;base64,${event.files.certificateBackground}`"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <validation-provider
            v-slot="{ errors }"
            vid="typesOfContacts"
          >
            <v-row class="mt-4">
              <v-col class="text-left label">
                <span class="display-1 font-weight-bold">Informações do Crachá</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="badgeField in availableBadgeFields"
                :key="badgeField.field"
                cols="4"
                class="pb-0 pt-1"
              >
                <v-checkbox
                  v-model="event.badgeFields"
                  hide-details="auto"
                  class="mt-0"
                  :label="badgeField.description"
                  :value="badgeField.field"
                  :error="errors.length > 0"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row
              v-if="errors.length > 0"
              no-gutters
            >
              <v-col
                no-gutters
                class="text-left"
              >
                <span class="body-2 error--text">
                  {{ errors[0] }}
                </span>
              </v-col>
            </v-row>
          </validation-provider>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import eventsApi from '@/api/events'
  import dateFormat from '@/utils/date-format'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      eventId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        event: { badgeFields: [] },
        files: {},
        dateMenu: false,
        dateEndMenu: false,
        dateFormated: null,
        dateEndFormated: null,
        dateFormatIso: dateFormat.iso,
        availableBadgeFields: [],
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      title () {
        return this.eventId ? 'Editar' : 'Adicionar'
      },
    },

    watch: {
      show (val) {
        if (!val) return

        this.loadBadgetFields()
        this.load()
      },
      'event.date': {
        immediate: true,
        handler (newVal) {
          this.dateFormated = dateFormat.ptBr(newVal)
        },
      },
      'event.dateEnd': {
        immediate: true,
        handler (newVal) {
          this.dateEndFormated = dateFormat.ptBr(newVal)
        },
      },
    },

    methods: {

      async load () {
        try {
          this.event = {
            date: null,
            dateEnd: null,
            badgeFields: [],
          }
          this.files = {}

          if (!this.eventId) {
            return
          }

          this.loading = true

          const response = await eventsApi.get(this.eventId)

          const date = response.data.event?.date?.substring(0, 10)
          const hour = response.data.event?.date?.substring(10)

          const dateEnd = response.data.event?.dateEnd?.substring(0, 10)
          const hourEnd = response.data.event?.dateEnd?.substring(10)

          const event = {
            ...response.data.event,
            date,
            hour,
            dateEnd,
            hourEnd,
          }

          this.event = event
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadBadgetFields () {
        try {
          const response = await eventsApi.listBadgeFields()
          this.availableBadgeFields = response.data.badgeFields
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          this.$refs.observer.reset()

          let date = ''
          if (this.event?.date) {
            date += this.event?.date
            date += this.event?.hour ? ` ${this.event?.hour}:00` : ` 00:00:00`
          }

          let dateEnd = ''
          if (this.event?.dateEnd) {
            dateEnd += this.event?.dateEnd
            dateEnd += this.event?.hourEnd ? ` ${this.event?.hourEnd}:00` : ` 00:00:00`
          }

          const event = {
            ...this.event,
            date: date || null,
            dateEnd: dateEnd || null,
            files: {},
          }

          for (const key of Object.keys(this.files)) {
            const file = this.files[key]
            event.files[key] = {
              base64: await this.toBase64(file),
              type: file.type
            }
          }

          if (this.eventId) {
            await eventsApi.update(this.eventId, event)
          } else {
            await eventsApi.insert(event)
          }

          this.show = false
          this.$snackbar.show({
            color: 'success',
            message: this.eventId ? this.$messages._('update_success') : this.$messages._('new_success')
          })
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

      async toBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        })
      },

      hasFile (key) {
        return !!this?.event?.files?.[key]
      },

      getFile (key) {
         return this?.event?.files?.[key]
      }

    },

  }
</script>
