var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.eventId)?_c('span',{staticClass:"body-2"},[_c('i',[_vm._v("#"+_vm._s(_vm.event.id))])]):_vm._e()]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Nome","error-messages":errors},model:{value:(_vm.event.name),callback:function ($$v) {_vm.$set(_vm.event, "name", $$v)},expression:"event.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('validation-provider',{attrs:{"vid":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"placeholder":"01/01/2022","label":"Data","hide-details":"auto","error-messages":errors,"append-icon":"mdi-calendar"},on:{"blur":function($event){_vm.event.date = _vm.dateFormatIso(_vm.dateFormated)},"click:append":function($event){_vm.dateMenu = true}},model:{value:(_vm.dateFormated),callback:function ($$v) {_vm.dateFormated=$$v},expression:"dateFormated"}})]}}],null,true)})]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{staticClass:"ma-0",attrs:{"no-title":""},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.event.date),callback:function ($$v) {_vm.$set(_vm.event, "date", $$v)},expression:"event.date"}})],1)],1),_c('v-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"vid":"hour"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],attrs:{"hide-details":"auto","label":"Hora","placeholder":"12:00","append-icon":"mdi-clock","error-messages":errors},model:{value:(_vm.event.hour),callback:function ($$v) {_vm.$set(_vm.event, "hour", $$v)},expression:"event.hour"}})]}}])})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('validation-provider',{attrs:{"vid":"dateEnd"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"placeholder":"01/01/2022","label":"Data Fim","hide-details":"auto","error-messages":errors,"append-icon":"mdi-calendar"},on:{"blur":function($event){_vm.event.dateEnd = _vm.dateFormatIso(_vm.dateEndFormated)},"click:append":function($event){_vm.dateEndMenu = true}},model:{value:(_vm.dateEndFormated),callback:function ($$v) {_vm.dateEndFormated=$$v},expression:"dateEndFormated"}})]}}],null,true)})]}}]),model:{value:(_vm.dateEndMenu),callback:function ($$v) {_vm.dateEndMenu=$$v},expression:"dateEndMenu"}},[_c('v-date-picker',{staticClass:"ma-0",attrs:{"no-title":""},on:{"input":function($event){_vm.dateEndMenu = false}},model:{value:(_vm.event.dateEnd),callback:function ($$v) {_vm.$set(_vm.event, "dateEnd", $$v)},expression:"event.dateEnd"}})],1)],1),_c('v-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"vid":"hourEnd"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],attrs:{"hide-details":"auto","label":"Hora Fim","placeholder":"13:00","append-icon":"mdi-clock","error-messages":errors},model:{value:(_vm.event.hourEnd),callback:function ($$v) {_vm.$set(_vm.event, "hourEnd", $$v)},expression:"event.hourEnd"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"vid":"placeCity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Cidade","error-messages":errors},model:{value:(_vm.event.placeCity),callback:function ($$v) {_vm.$set(_vm.event, "placeCity", $$v)},expression:"event.placeCity"}})]}}])})],1),_c('v-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"vid":"nextEnrolledSequence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":!!_vm.event.id ? 'Próximo Número Certificado' : 'Número Inicial Certificado',"error-messages":errors,"disabled":!!_vm.event.id},model:{value:(_vm.event.nextEnrolledSequence),callback:function ($$v) {_vm.$set(_vm.event, "nextEnrolledSequence", $$v)},expression:"event.nextEnrolledSequence"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"certificateText"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"hide-details":"auto","label":"Texto do Certificado","rows":"3","error-messages":errors},model:{value:(_vm.event.certificateText),callback:function ($$v) {_vm.$set(_vm.event, "certificateText", $$v)},expression:"event.certificateText"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"hide-details":"auto","label":"Detalhes","rows":"3","error-messages":errors},model:{value:(_vm.event.details),callback:function ($$v) {_vm.$set(_vm.event, "details", $$v)},expression:"event.details"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"banner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"prepend-icon":"","accept":"image/*","hide-details":"auto","label":"Banner","error-messages":errors},model:{value:(_vm.files.banner),callback:function ($$v) {_vm.$set(_vm.files, "banner", $$v)},expression:"files.banner"}})]}}])})],1)],1),(_vm.hasFile('banner'))?_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-img',{attrs:{"src":("data:image/jpeg;base64," + (_vm.event.files.banner))}})],1)],1):_vm._e()],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"badgeBackground"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"prepend-icon":"","accept":"image/*","hide-details":"auto","label":"Fundo Crachá","error-messages":errors},model:{value:(_vm.files.badgeBackground),callback:function ($$v) {_vm.$set(_vm.files, "badgeBackground", $$v)},expression:"files.badgeBackground"}})]}}])})],1)],1),(_vm.hasFile('badgeBackground'))?_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-img',{attrs:{"src":("data:image/jpeg;base64," + (_vm.event.files.badgeBackground))}})],1)],1):_vm._e()],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"certificateBackground"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"prepend-icon":"","accept":"image/*","hide-details":"auto","label":"Fundo Certificado","error-messages":errors},model:{value:(_vm.files.certificateBackground),callback:function ($$v) {_vm.$set(_vm.files, "certificateBackground", $$v)},expression:"files.certificateBackground"}})]}}])})],1)],1),(_vm.hasFile('certificateBackground'))?_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-img',{attrs:{"src":("data:image/jpeg;base64," + (_vm.event.files.certificateBackground))}})],1)],1):_vm._e()],1)],1),_c('validation-provider',{attrs:{"vid":"typesOfContacts"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"text-left label"},[_c('span',{staticClass:"display-1 font-weight-bold"},[_vm._v("Informações do Crachá")])])],1),_c('v-row',_vm._l((_vm.availableBadgeFields),function(badgeField){return _c('v-col',{key:badgeField.field,staticClass:"pb-0 pt-1",attrs:{"cols":"4"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"auto","label":badgeField.description,"value":badgeField.field,"error":errors.length > 0},model:{value:(_vm.event.badgeFields),callback:function ($$v) {_vm.$set(_vm.event, "badgeFields", $$v)},expression:"event.badgeFields"}})],1)}),1),(errors.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"no-gutters":""}},[_c('span',{staticClass:"body-2 error--text"},[_vm._v(" "+_vm._s(errors[0])+" ")])])],1):_vm._e()]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.loading)?_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" Salvar ")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }